<template>
  <Auth
    class="custom-inputs"
    title="Create new password"
    description="Enter the OTP code you received via email to reset your new password"
  >
    <template>
      <!-- <ValidationObserver v-slot="{ invalid, handleSubmit }" tag="div"> -->
      <div class="mt-5">
        <InputBox
          v-model.number="otp"
          type="text"
          pattern="[0-9]+"
          title="OTP"
          :hide-title="true"
          name="OTP"
          placeholder="Enter OTP"
          class="border-none"
          rules="required|max:6"
        />
        <InputBox
          v-model="password"
          title="Password"
          :hide-title="true"
          name="Password"
          type="password"
          placeholder="Password"
          vid="confirmation"
          class="border-none"
          autocomplete="new-password"
          rules="min:8|required"
        />
      </div>
      <div>
        <InputBox
          v-model="confirmPassword"
          type="password"
          title="CONF_PASS"
          :hide-title="true"
          name="CONF_PASS"
          placeholder="Confirm Password"
          class="border-none"
          autocomplete="new-password"
          rules="required|confirmed:confirmation"
        />
      </div>
      <button
        type="button"
        :disabled="isLoading"
        class="focus:outline-none focus:shadow-md mt-6 mb-20 text-center w-full text-base text-white font-roboto h-12 flex justify-center items-center primary-button"
        @click.prevent="handleSubmit(passwordReset(invalid))"
      >
        <span v-if="isLoading">
          <Loader />
        </span>
        <span v-else>Change Password</span>
      </button>
      <!-- </ValidationObserver> -->
    </template>
  </Auth>
</template>

<script>
// import { ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import Auth from '@layouts/auth.vue'
import InputBox from '@components/UiElements/UiInputBox.vue'
import { mapActions } from 'vuex'
import Loader from '@components/BaseComponent/Loader.vue'
export default {
  page: {
    title: 'Reset Password',
    meta: [
      {
        name: 'description',
        content: 'Reset Password ',
      },
    ],
  },
  components: {
    Auth,
    InputBox,
    Loader,
    // ValidationObserver,
  },
  data() {
    return {
      isLoading: false,
      password: '',
      confirmPassword: '',
      otp: '',
      loginPage: 'loginPage',
      email: '',
    }
  },
  mounted() {
    this.OTPHandler()
  },
  methods: {
    passwordReset(invalid = false) {
      if (invalid) return
      this.isLoading = true
      this.resetOwnerPassword({
        password: this.password,
        otp: this.otp,
        email: this.email,
      })
        .then(() => {
          this.$store.commit('toast/NEW', {
            message: 'Password reset successfully',
            type: 'success',
          })
          this.$router?.push({ name: 'login' })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    OTPHandler() {
      let otp = this.$route.query.otp
      let email = this.$route.query.email
      if (otp) this.otp = otp
      if (email) this.email = email
    },
    ...mapActions('auth', ['resetOwnerPassword']),
  },
}
</script>

<style lang="scss" module>
@import '@design';
</style>

<style lang="scss">
@import '@src/router/views/auth/auth.scss';
</style>
